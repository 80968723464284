const searchPopUp = {
    popUpNode: null,
    openButtons: [],
    closeButton: null,
    openSearchPopUpReferences: [],
    closeSearchPopUpReference: null,
    closeListener: null,
    outerClickFn: null,
    escClickFn: null,

    init() {
        let self = this;
        self.popUpNode = document.querySelector('.js-search-popup');

        if(! self.popUpNode) {
            return;
        }

        window.initPackage('https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.4/gsap.min.js').then(function() {
            self._createListeners();
        })
        .catch(function(error) {
            console.error(error);
        });
    },

    reInit() {
        let self = this;

        self._closeSearchBarPopUp(self);
        self._destroyListeners();
        self.init();
    },

    _createListeners() {
        let self = this;

        self.openButtons = document.querySelectorAll('.js-search-button');
        self.closeButton = self.popUpNode.querySelector('.js-search-bar-close-button');


        self.closeSearchPopUpReference = self._closeSearchBarPopUp.bind(null, self);

        self.openButtons.forEach((openButton, index) => {
            self.openSearchPopUpReferences[index] = self._openSearchBarPopUp.bind(null, self);
            self.openButtons[index].addEventListener('click', self.openSearchPopUpReferences[index], false);
        });
        
        self.closeButton.addEventListener('click', self.closeSearchPopUpReference, false);  
        
    },

    _destroyListeners() {
        let self = this;
        
        self.openButtons.forEach(openButton => {
            openButton.removeEventListener('click',  self.openSearchPopUpReference, false);
        });

        self.closeButton.removeEventListener('click',  self.closeSearchPopUpReference, false);
    },

    _openSearchBarPopUp(self) {
        self.popUpNode.classList.remove('hidden');
        self.popUpNode.querySelector('input').focus();

        window.navbarMenuConst.navbar.closeMainMenu();

        gsap.from(self.popUpNode, {autoAlpha: 0, duration: 0.3});
        gsap.from(self.popUpNode.querySelector('.js-search-popup > div'), {autoAlpha: 0, duration: 0.3, y: -50});


        self.outerClickFn = self._outerClick.bind(null, self);
        self.popUpNode.addEventListener('click', self.outerClickFn, false);

        self.escClickFn = self._keyResolver.bind(null, self);
        
        document.addEventListener('keydown', self.escClickFn, false);    
    },

    _closeSearchBarPopUp(self) {
        gsap.to(self.popUpNode, {autoAlpha: 0, duration: 0.2, delay: 0.1, onComplete:function(){
            self.popUpNode.removeAttribute('style');
            self.popUpNode.classList.add('hidden');
        }});
    },

    _outerClick(self) {
    
        if(event.target === self.popUpNode) {
            self._closeSearchBarPopUp(self);
        }

        self.popUpNode.removeEventListener('click', self.outerClickFn, false);
    },

    _keyResolver(self) {
        if(event.key === 'Escape') {
            self._closeSearchBarPopUp(self);
        }
        
        document.removeEventListener('keydown', self.escClickFn, false);
    }
};

window.searchPopUp = searchPopUp;

searchPopUp.init();

document.addEventListener('LokiRequestEnd', function () {
    searchPopUp.reInit();
});