const animations = {
    init() {
        const scriptUrls = [
            'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.4/gsap.min.js',
            'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.4/ScrollTrigger.min.js',
          ];

        let self = this;
        
        window.initPackages(scriptUrls).then(() => {
            self._construct();
        }).catch((error) => {
            console.error(error);
        });
    },

    _construct() {
        let self = this;
        gsap.registerPlugin(ScrollTrigger);
        if(document.querySelectorAll('.js-fade-in-bottom').length > 0) {
            self._fadeInBottom();
        }

        if(document.querySelectorAll('.js-fade-in-right').length > 0) {
            self._fadeInRight();
        }

        if(document.querySelectorAll('.js-img-reveal').length > 0) {
            self._imgReveal();
        }

        if(document.querySelectorAll('.js-fade-in-bottom-single').length > 0) {
            self._singleItem();
        }
    },

    _fadeInBottom() {
        let self = this;
        let items = document.querySelectorAll('.js-fade-in-bottom');

        if(window.innerWidth > 768) {
            items.forEach(item => {
                gsap.to(item.querySelectorAll('.js-anim-item'), {
                    scrollTrigger: {
                        trigger: item,
                        start: "50% bottom",
                    },
            
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'power4.easeOut',
                    stagger: 0.1,
                    
                });
            });
        }   
        else {
            items.forEach(item => {
                item.querySelectorAll('.js-anim-item').forEach(elem => {
                    gsap.to(elem, {
                        scrollTrigger: {
                            trigger: elem,
                        },
                
                        y: 0,
                        autoAlpha: 1,
                        duration: 0.5,
                        ease: 'power4.easeOut',
                        stagger: 0.1,
                        
                    });
                });
                
            });
        }
        
        
    },

    _fadeInRight() {
        let self = this;
        let items = document.querySelectorAll('.js-fade-in-right');
        
        items.forEach(item => {
            gsap.to(item.querySelectorAll('.js-anim-item'), {
                scrollTrigger: {
                    trigger: item,
                    start: 'top center+=15%',
                    end: 'bottom bottom',
                },
        
                x: 0,
                autoAlpha: 1,
                duration: 0.5,
                ease: 'power4.easeOut',
                stagger: 0.1,
            });
        });
    },

    _imgReveal() {
        let self = this;

        let revealContainers = document.querySelectorAll(".js-img-reveal");

        revealContainers.forEach((container) => {
            let image = container.querySelector("img");
            let tl = gsap.timeline({
                scrollTrigger: {
                trigger: container,
                }
            });

            tl.set(container, {autoAlpha: 1});

            tl.from(container, 1, {
                xPercent: -100,
                ease: 'power2.out'
            });
            tl.from(image, 1, {
                xPercent: 100,
                
                delay: -1,
                ease: 'power2.out'
            });
        });
    },


    _singleItem() {
        let items = document.querySelectorAll('.js-fade-in-bottom-single');
        items.forEach(item => {
            item.querySelectorAll('.js-anim-item').forEach(elem => {
                gsap.to(elem, {
                    scrollTrigger: {
                        trigger: elem,
                    },
            
                    y: 0,
                    autoAlpha: 1,
                    duration: 0.5,
                    ease: 'power4.easeOut',
                    stagger: 0.1,
                });
            });
            
        });
    }
};

animations.init();