import Splide from '@splidejs/splide';

const homePageHeaderSlider = {
    _slider: null,
    timeout: null,
    delay: 250,

    init: function () {
        let self = this;

        if(! document.querySelector('.js-slider')) {
            return;
        }

        const scriptUrl = 'https://cdn.jsdelivr.net/npm/@splidejs/splide@4.1.4/dist/js/splide.min.js';

        window.initPackage(scriptUrl).then(() => {
            self._sliderCreate();
        }).catch((error) => {
            console.error(error);
        });
    },

    _sliderCreate() {
        let self = this;
    
        self._slider = new Splide( '.js-slider', {
            type: 'loop',
                speed: 800,
                accessibility: true,
                arrows: false,
                keyboard: true,
                autoplay: true,
                pagination: false,
        }).mount();
        self._initClickEvent();
    },

    _initClickEvent() {
        let self = this;

        let nextButton = document.querySelector('.js-header-slider__nav-next');
        let prevButton = document.querySelector('.js-header-slider__nav-prev');

        if (nextButton) {
            nextButton.addEventListener('click', self._nextSlide, false);
        }

        if (prevButton) {
            prevButton.addEventListener('click', self._prevSlide, false);
        }

    },

    _nextSlide() {
        homePageHeaderSlider._slider.go('>');
    },

    _prevSlide() {
        homePageHeaderSlider._slider.go('<');
    },

    _goTo(self, index) {
        self._slider.go(index);
    },


    _destroyButtonsEvent() {
        let self = this;

        let nextButton = document.querySelector('.js-header-slider__nav-next');
        let prevButton = document.querySelector('.js-header-slider__nav-prev');

        if(!nextButton && !prevButton) {
            return;
        }

        
        nextButton.removeEventListener('click', self._nextSlide, false);
        prevButton.removeEventListener('click', self._prevSlide, false);
        
    },

    _destroy() {
        let self = this;
        self._slider.destroy(true);
        self._slider = null;
    },


    reInit() {
        let self = this;
        let node = document.querySelector('.js-slider');

        if (!node) {
            if (self._slider) {
                self._destroy();
            }

            return;
        }

        if (self._slider) {
            self._destroy();
        }

        self.init();
    },
};


window.homePageHeaderSlider = homePageHeaderSlider;

let slider = document.querySelector('.js-slider');
if (slider) {
    window.homePageHeaderSlider.init();
}

var doit;

function resizedw(){

    window.homePageHeaderSlider.reInit();
}


window.addEventListener('resize', function() {
    // clear the timeout
    clearTimeout(doit);
    // start timing for event "completion"
    doit = setTimeout(resizedw, 250);
});


