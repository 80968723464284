// inicializace objektu pro ukládání balíčků
if (!window.packageStore) {
    window.packageStore = {};
}
  
window.initPackage = function(url) {
    return new Promise(function(resolve, reject) {
      // kontrola, zda balíček již byl načten
        if (window.packageStore[url]) {
            resolve(window.packageStore[url]);
            
        } else {
        // inicializace balíčku
            var script = document.createElement('script');
            //window.packageStore[url] = true;
            script.onload = function() {
                window.packageStore[url] = true;
                resolve(true);
            };
            script.onerror = function() {
                reject(new Error('Nepodařilo se načíst balíček.'));
            };
            script.src = url;
            document.head.appendChild(script);
        }
    });
}


window.initPackages = function (scriptUrls) {
    const promises = [];

    scriptUrls.forEach((url) => {
        if (!window.packageStore[url]) {
            promises.push(window.initPackage(url));
        }
    });

    return Promise.all(promises);
  }