const authorsSwitcher = {
    init() {
        if (document.querySelector('.js-authors')) {
            let self = this;
            self._fireInTheHole();
        }
    },

    _fireInTheHole() {
        let self = this;
        let authorsNode = document.querySelector('.js-authors');
        
        if(authorsNode.dataset.init === "1") {
            return;
        }
        authorsNode.dataset.init = "1";
        

        let images = authorsNode.querySelectorAll('.c-authors__imgs-item');
        let buttons = authorsNode.querySelectorAll('.c-authors__buttons-item');

        images.forEach((image, index) => {
            image.dataset.count = index;
        });

        buttons.forEach((button, index) => {
            button.dataset.count = index;
            button.addEventListener('click', function() {
                self._changeActiveItem(this);
            });
        });
    },

    _changeActiveItem(button) {
        let self = this;
     
        let activeItem = document.querySelector('.c-authors__imgs-item--active');
        let activeButton = document.querySelector('.c-authors__buttons-item--active');
        
        if(button.dataset.count === activeItem.dataset.count) {
            return;
        }
        
        activeItem.classList.remove('c-authors__imgs-item--active');
        activeButton.classList.remove('c-authors__buttons-item--active');

        document.querySelector('.c-authors__imgs-item[data-count="' + button.dataset.count + '"]').classList.add('c-authors__imgs-item--active');
        button.classList.add('c-authors__buttons-item--active');
        
    }
} 

authorsSwitcher.init();