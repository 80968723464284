const gallery = {
    galleries: [],

    init() {
        let self = this;

        let galleryNodes = document.querySelectorAll('.js-gallery')

        if (! galleryNodes.length > 0) {
            return;
        }

        const scriptUrls = [
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/lightgallery.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/zoom/lg-zoom.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/thumbnail/lg-thumbnail.min.js'
          ];

        window.initPackages(scriptUrls).then(() => {
            self._createGalleries(galleryNodes);
        }).catch((error) => {
            console.error(error);
        });
    },

    reInit(){
        let self = this;

        self.destroyGalleries();
        self.init();
    },

    openGallery(node) {
        let self = this;
        let galleryId = node.closest('.js-gallery-wrapper').querySelector('.js-gallery').dataset.id;        
        self.galleries[galleryId].openGallery(3);
    },

    _createGalleries(galleryNodes) {
        let self = this;

        galleryNodes.forEach((galleryNode, index) => { 
            self.galleries[index] = lightGallery(galleryNode, {
                plugins: [lgZoom, lgThumbnail],
                licenseKey: 'your_license_key',
                speed: 500,
                exThumbImage: 'data-thumb'
            });

            galleryNode.dataset.id = index;
        });
    },

    destroyGalleries() {
        let self = this;

        if(self.galleries) {
            self.galleries.forEach(gallery => {
                gallery.destroy();
            });
        }

        self.galleries = [];
    }
}
window.gallery = gallery;
window.gallery.init();


const articleGallery = {
    galleries: [],

    init() {
        let self = this;

        let galleryNodes = document.querySelectorAll('.typography')

        if (! galleryNodes.length > 0) {
            return;
        }

        const scriptUrls = [
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/lightgallery.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/zoom/lg-zoom.min.js',
            'https://cdn.jsdelivr.net/npm/lightgallery@2.7.1/plugins/thumbnail/lg-thumbnail.min.js'
          ];

        window.initPackages(scriptUrls).then(() => {
            self._createGalleries(galleryNodes);
        }).catch((error) => {
            console.error(error);
        });


        
    },

    reInit(){
        let self = this;

        self.destroyGalleries();
        self.init();
    },

    _createGalleries(galleryNodes) {
        let self = this;

        galleryNodes.forEach((galleryNode, index) => { 
            self.galleries[index] = lightGallery(galleryNode, {
                selector: 'img',
                plugins: [lgZoom, lgThumbnail],
                licenseKey: 'your_license_key',
                speed: 500,
                exThumbImage: 'src'
            });
        });
        
    },

    destroyGalleries() {
        let self = this;

        if(self.galleries) {
            self.galleries.forEach(gallery => {
                gallery.destroy();
            });
        }

        self.galleries = [];
    }
}

articleGallery.init();