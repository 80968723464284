
class navbarMenu {
    constructor(node) {
        let self = this;
        self.node = node;
        self.behaviour();
    }

    behaviour() {
        let self = this;

        const scriptUrl = 'https://cdnjs.cloudflare.com/ajax/libs/gsap/3.11.4/gsap.min.js';

        window.initPackage(scriptUrl).then(() => {
            self.initMainMenu();
        }).catch((error) => {
            console.error(error);
        });
    }

    reInit() {
        let self = this;

        self.closeMainMenu();
        self.closeAllDropDownMenu();
        self.destroyMainMenu();
        self.destroySubMenu();
        self.behaviour();
    }

    initMainMenu() {
        let self = this;
        self.initSubMenu();
        self.navbarButton = document.querySelector('.js-menu-button');
        self.navbarContent = self.node.querySelector('.js-navbar-content');

        self.toggleMainMenuReference = self.toggleMainMenu.bind(null, self);
        self.navbarButton.addEventListener('click', self.toggleMainMenuReference, false);
    }

    destroyMainMenu() {
        let self = this;

        if(self.navbarButton) {
            self.navbarButton.removeEventListener('click',  self.toggleMainMenuReference, false);
        }
    }

    toggleMainMenu(self) {
        self.navbarContent.dataset.opened === "true" ? self.closeMainMenu() : self.openMainMenu();
    }

    openMainMenu() {
        let self = this;

        self.navbarButton.classList.add('open');
        self.navbarContent.dataset.opened = "true";
        self.navbarContent.classList.remove('hidden');
        self.node.classList.add('js-navbar--opened');
        document.querySelector('body').classList.add('overflow-hidden');
        gsap.from(self.navbarContent, {duration: 0.3, autoAlpha:0, right: '-100%'});
    }

    closeMainMenu() {
        let self = this;
       
        if(self.navbarButton && self.navbarButton.classList.contains('open')) {
            self.navbarButton.classList.remove('open');
            document.querySelector('body').classList.remove('overflow-hidden');
            self.node.classList.remove('js-navbar--opened');
        }

        if(self.navbarContent && self.navbarContent.dataset.opened === 'true') {
            
            gsap.to(self.navbarContent, {duration: 0.5, autoAlpha:0, right: '-100%', onComplete:function() {
                self.navbarContent.dataset.opened = "false";
                self.navbarContent.classList.add('hidden');
                self.navbarContent.removeAttribute('style');
            }});

        }

        if(! self.dropDownNodes) {
                return;
        }

        if (self.dropDownNodes.length && self.dropDownListeners.length) {
            
            self.closeAllDropDownMenu();
        }
    }

    initSubMenu() {
        let self = this;

        self.dropDownNodes = self.node.querySelectorAll('[data-is-dropdown="true"]');
        self.dropDownListeners = [];
        self.toggleDropDownMenuReference = self.toggleDropDownMenu.bind(null, self);

       self.dropDownNodes.forEach((node, index) => {
            self.dropDownListeners[index] = node.querySelector('.navbar-item__button');
            self.dropDownListeners[index].addEventListener('click', self.toggleDropDownMenuReference, false);
        });
    }

    destroySubMenu() {
        let self = this;

        if (self.dropDownListeners) {
            self.dropDownListeners.forEach(element => {
                element.removeEventListener('click', self.toggleDropDownMenuReference, false);
            });
        }
    }

    toggleDropDownMenu(self) {
        let button = event.target.closest('div');        
        let dropDown = button.parentElement.querySelector('[data-dropdown-opened]');

        dropDown.dataset.dropdownOpened === "true" ? self.closeDropDownMenu(dropDown): self.openDropDownMenu(dropDown);
    }

    openDropDownMenu(dropDown) {
        dropDown.parentElement.querySelector('[aria-expanded]').setAttribute('aria-expanded', 'true');
        dropDown.classList.remove('hidden');
        dropDown.dataset.dropdownOpened = "true";
    }

    closeDropDownMenu(dropDown) {
        dropDown.parentElement.querySelector('[aria-expanded]').setAttribute('aria-expanded', 'false');
        dropDown.classList.add('hidden');
        dropDown.dataset.dropdownOpened = "false";
    }

    closeAllDropDownMenu() {
        let self = this;

        if(! self.dropDownNodes) {
            return;
        }

        self.dropDownNodes.forEach(node => {
            let dropDown = node.querySelector('[data-dropdown-opened]');
            if (dropDown.dataset.dropdownOpened === "true") {
                self.closeDropDownMenu(dropDown);
            }
        });
    }

}



const navbarMenuConst = {
    navbar: null,
    timeout: false, // holder for timeout id
    delay: 250, // delay after event is "complete" to run callback
    initValue: null,

    init() {
        let self = this;

        let node = document.querySelector('.js-navbar');

        if (node) {
            self.navbar = new navbarMenu(node);
        }
    },

    reInit(self) {

        if(! self) {
            self = this;        
        }

        if (self.navbar) {
            self.navbar.closeMainMenu();
        }
        
        self.navbar.reInit();
    },
};

window.navbarMenuConst = navbarMenuConst;

window.navbarMenuConst.init();

/*window.addEventListener('resize', function() {
    clearTimeout(navbarMenu.timeout);
    window.navbarMenu.timeout = setTimeout(window.navbarMenu.reInit.bind(null, window.navbarMenu), window.navbarMenu.delay);
});*/
